body {
  margin: 0;
  font-family: 'Ubuntu';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul{
  list-style: none;
}

ul li::before{
  content: '\2022';
  color: #429098;
  font-weight: bold;
  font-size: 18px;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

